.videoManagement-page {
  .table-container {
    table {
      table-layout: auto !important ;
    }
  }
  .ant-table-content {
  }
}
.video-page {
  .ant-steps {
    max-width: 75%;
    margin: 0 auto;
    &.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
      padding-left: 2.5rem;
      &:first-child {
        padding-left: 0;
      }
    }
    .ant-steps-item-container {
      display: flex;
      align-items: center;
    }
    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background: #416dfc;
    }
    .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background: #d6def7;
    }
    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background: repeating-linear-gradient(
          to right,
          #416dfc 0,
          #416dfc 4px,
          transparent 4px,
          transparent 8px
        )
        top;
      background-size: 100% 1px;
      background-repeat: no-repeat;
    }
    .ant-steps-item-process,
    .ant-steps-item-finish {
      & > .ant-steps-item-container {
        & > .ant-steps-item-content > .ant-steps-item-title {
          font-weight: 700;
          color: #0d1734;
        }
        & > .ant-steps-item-icon {
          background: #416dfc !important;
          svg {
            --color: #fff;
          }
        }
      }
    }
    .ant-steps-item-title {
      font-size: 1rem;
      font-weight: 500;
      padding-right: 2.5rem;
      color: #0d1734;
    }
    .ant-steps-item-icon {
      width: 2.75rem !important;
      height: 2.75rem;
      border: 0;
      background-color: #e2e8fa !important;
      margin-right: 10px;

      .ant-steps-icon {
        width: 2.75rem;
        height: 2.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          --color: #3f4a6c;
        }
      }
    }
  }
  .form-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  }
  .section-body {
    .form-container {
      margin: 0 auto;

      &.publish-form {
        max-width: 80%;
      }
      &.video-element-form {
        max-width: 90%;
      }
      &.source-form {
        max-width: 31.25rem;
      }
      &.details-form {
        max-width: 90%;
        margin: 0;
      }
      .outline-btn {
        border: 1px solid #416dfc;
        border-radius: 4px;
        font-size: 1rem;
        padding: 0 1.375rem;
        height: 2.5rem;
        color: #416dfc;
        font-weight: 400;
      }
      .action-col {
        padding: 0;
        margin-left: auto;
        .action-btn {
          background: #f2f4fa;
          padding: 0;
          width: 2.75rem;
          height: 2.75rem;
          border-radius: 4px;
          margin-left: 0.625rem;
        }
        .close-btn {
          padding: 0;
          img {
            width: 1.3125rem;
          }
        }
      }
      .add-video-items {
        .video-item {
          li {
            border: 1px solid #adb2cc;
            border-radius: 4px;
            padding: 0.75rem 1.25rem;
            margin-bottom: 1.25rem;
            .ant-form-item{
              margin-bottom: 0;
            }
            .video-item-row {
              display: flex;
              align-items: center;
            }
            .placeholder-content {
              display: flex;
              align-items: center;
              cursor: pointer;
              margin: 10px 0;
              .content-col {
                flex: 1;
                h3 {
                  font-size: 1.25rem;
                  color: #0d1734;
                  font-weight: 700;
                  margin-bottom: 4px;
                }
                p {
                  color: #7d829a;
                  font-size: 1rem;
                }
              }

              .arrow-icon {
                width: 18px;
                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .video-option-items {
        .option-label {
          margin-bottom: 0.9375rem;
          span {
            color: #0d1734;
            font-size: 0.875rem;
            margin-right: 0.75rem;
          }
        }
        .video-option-list {
          li {
            display: flex;
            align-items: center;
            padding: 1.5625rem 1.25rem;
            border: 1px solid #adb2cc;
            border-radius: 4px;
            margin-bottom: 1.25rem;

            p {
              display: flex;
              align-items: center;
              color: #0d1734;
              font-size: 1.25rem;
              img {
                width: 1.5rem;
                margin-right: 1.125rem;
              }
            }

            .tag-container {
              padding: 0 10px;
              flex: 1;
            }
          }
        }
      }
      .details-row {
        padding-bottom: 2.1875rem;
        article {
          display: inline-block;
          width: 100%;
          margin-bottom: 0.875rem;
          h3 {
            font-size: 0.875rem;
            color: #0d1734;
            font-weight: 700;
          }
          p {
            font-size: 0.875rem;
            color: #999999;
            margin: 4px 0 0.375rem;
          }
        }
        .primary-outline {
          width: 100%;
          min-height: 5.1875rem;
        }
      }
      .toggle-btn-row {
        border: 0;
        .form-label {
          margin-bottom: 1.25rem;
        }
      }
      .video-element-row {
        margin-bottom: 1.5625rem;
        .content-col {
          label {
            font-size: 0.875rem;
            color: #0d1734;
          }
          p {
            font-size: 0.875rem;
            color: #7d829a;
            margin-top: 5px;
            strong {
              font-weight: 700;
            }
          }
          .ant-checkbox-wrapper {
            margin-top: 0.9375rem;
          }
        }
      }

      .publish-items {
        border: 1px solid #adb2cc;
        border-radius: 4px;
        padding: 1.875rem;
        .ant-radio-wrapper {
          color: #0d1734;
          font-size: 0.875rem;
          p {
            font-size: 0.875rem;
            color: #7d829a;
            font-weight: 400;
          }
        }
        .publish-options {
          padding-left: 30px;
          .ant-radio-wrapper {
            width: 100%;
            margin: 0.625rem 0;
          }
          .date-row {
            margin: 1.25rem 0 0.625rem;
            .ant-picker {
              border: 0;
              border-bottom: 1px solid #adb2cc;
              border-radius: 0px;
              padding: 0.4375rem 0.5rem 0.4375rem 0px;
              color: #000;
              box-shadow: none !important;
              input {
                font-size: 14px;
                color: #0d1734;
              }
              &:first-child {
                margin-right: 2.5rem;
              }
            }
          }
        }
        .info-msg {
          margin-top: 0.9375rem;
        }
      }
      .info-container {
        display: flex;
        align-items: flex-start;
        padding: 1.375rem 1.625rem;
        margin-top: 1.25rem;
        border-radius: 4px;
        background: rgba(247, 247, 247, 0.8);
        img {
          margin-right: 7px;
          margin-top: 2px;
        }
        p {
          font-size: 0.875rem;
          font-weight: 700;
          color: #2e4cb2;
          margin-bottom: 0.3125rem;
        }
        span {
          display: block;
          font-size: 14px;
          color: #667ece;
        }
      }
    }
  }
}
