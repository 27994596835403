$primary: #416dfc;
$base-color: #393e58;
$dark: #333333;
$dark-varient: #e7eaf1;
$dark-shade: #e1e8eb;
$border-color: #dbdde8;
$error-color: #ff3131;
$success-color: #28b75f;
$success-varient-color: #2ed47a;
$good-color: #f49a26;
$average-color: #ff5353;
$unpublished-color: #7d829a;
