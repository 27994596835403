.video-detail-page {
    width: 70%;
    float: left;
    padding-bottom: 0.9375rem;

    .ant-tabs-top>.ant-tabs-nav::before {
        border-bottom: 1px solid #D4D4D4;
    }

    .ant-tabs-tab {
        padding: 12px 20px;
        font-size: 14px;
        font-weight: 500;
        color: #000000;

        .ant-tabs-ink-bar {
            color: #098EDF;
        }

        &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {

                color: #098EDF;
            }
        }
    }

    .video-detail-card {
        padding: 2.8125rem 1.5625rem;
        background: #fff;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 6px;

        h3 {
            font-weight: 600;
            font-size: 1.125rem;
            color: #000000;
            margin-bottom: 1.875rem;
            text-align: center;
        }

        .overview-card {
            display: flex;
            align-items: center;
            padding: 1.6875rem 1.875rem;
            border: 1px solid #B9B9B9;
            border-radius: 4px;

            .overview-card-content {
                flex: 1;

                p {
                    color: #666666;
                    font-size: 0.875rem;
                    font-weight: 500;
                    margin-bottom: 4px;
                }

                .count {
                    display: flex;
                    align-items: center;

                    span {
                        font-size: 1.5rem;
                        color: #000000;
                        font-weight: 600;
                    }

                    svg {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }

    // .video-container {
    //     margin: 3.75rem 0 1.5625rem;

    //     video {
    //         width: 100%;
    //     }
    // }

  
    .video-detail-block {
        h4 {
            font-size: 1.125rem;
            font-weight: 600;
            color: #000000;
            margin-bottom: 0.9375rem;
        }

        .description-block {
            label {

                display: block;
                font-weight: 500;
                font-size: 15px;
                color: #000000;
                margin-bottom: 5px;
            }

            p {
                font-size: 14px;
                font-weight: 500;
                color: #333333;
            }

            a {
                font-size: 14px;
                color: #2D9CDB;
            }
        }
    }

   
}

.video-features-panel {
    float: left;
    width: 25%;
   // height: 40%;
    padding: 10px 30px;
    background: #FFFFFF;
    border: 1px solid #E6E9EB;
    border-radius: 6px;
    margin-top: 20px;
    margin-left: 20px;
    li {
        display: flex;
        padding: 15px 0;
        border-bottom: 1px solid #DCDCDC;

        &:last-child {
            border-bottom: 0;
        }

        label {
            font-size: 14px;
            font-weight: 600;
            color: #000000;
            flex: 1;
            padding-right: 10px;
        }

        p {
            font-size: 14px;
            color: #333333;
        }
    }
}
.video-detail-panel {
    float: left;
    width: 25%;
   //height: 40%;
    background: #FFFFFF;
    border: 2px solid #2196F3;
    border-radius: 6px;
    margin-top: 50px;
    margin-left: 20px;

    .video-panel-header {
        padding: 1.125rem 1.6875rem;
        border-bottom: 1px solid #E6E9EB;

        h3 {
            font-size: 1.0625rem;
            color: #000000;
            font-weight: 600;
        }
    }

    .video-panel-body {
        padding: 1.25rem 1.5625rem;

        .company {
            margin-bottom: 25px;

            img {
                height: 35px;
                margin-bottom: 6px;
            }

            p {
                display: flex;
                font-size: 13px;
                font-weight: 600;
                color: #000000;

                span {
                    font-weight: 400;
                    color: #2196F3;
                }
            }
        }

        .video-detail-col {
            padding: 10px 0;

            label {
                color: #000000;
                font-weight: 500;
                font-size: 14px;
            }

            p {
                color: #333333;
                font-size: 15px;
            }
        }
    }
}

.video-player {
    position: relative;
    height: 50vh;
    overflow: hidden;
    .video-detail{
      position: absolute;
      top: 2.5rem;
      left: 2.5rem;
      padding: 0 1.5625rem;
      z-index: 1;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 4px;
        background: black;
      }
      .rating{
        font:bold 10px 'Roboto', sans-serif;
        color: #fff;
      }
      p{
        font:500 10px 'Roboto', sans-serif;
  
        margin: 0;
        color: #fff;
  
      }
    }
    .player-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.2);
      padding:0 0 2.5rem;
      .loader {
        width: 80px;
        height: 80px;
        border: 6px solid rgba(168, 168, 168, 0.6);
        border-left-color: black;
        border-radius: 100%;
        animation: rotate 1s linear infinite;
      }
    }
    .video-container {
        margin: 3.75rem 0 1.5625rem;
    }
    video {
      width: 100%;
      height: 100%;
      &::-webkit-media-controls {
        display: none;
      }
    }
    .video-controls {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 1.5rem;
      .full-screen-icon {
        width: 1rem;
      }
      .video-btn {
        background: transparent;
        border: 0;
        padding: 0;
        outline: none;
      }
      .back-btn {
        display: flex;
        img {
          width: 0.5rem;
        }
      }
      .cast-btn {
        img {
          width: 1rem;
        }
      }
      .controls-header {
        display: flex;
        align-items: center;
        .left-col {
          display: flex;
          align-items: center;
          h2 {
            margin: 0;
            color: #fff;
            font-size: 1rem;
          }
        }
        .right-col {
          margin-left: auto;
        }
      }
      .controls-main {
        display: flex;
        align-items: center;
        justify-content: center;
        .video-controls-block {
          display: flex;
          align-items: center;
        }
        .video-btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 3rem;
          height: 3rem;
          border-radius: 100%;
          margin: 0 1.125rem;
          background: rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          img {
            max-width: 100%;
            width: 1.5rem;
          }
          &:hover {
            background: rgba(0, 0, 0, 0.4);
          }
          &:active {
            background: rgba(0, 0, 0, 0.6);
          }
        }
  
        .rewind-btn {
          img {
            width: 1rem;
            height: 1rem;
          }
        }
        .forward-btn {
          img {
            width: 1rem;
            height: 1rem;
          }
        }
      }
      .controls-footer {
        .video-progress-bar {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          height: 8px;
          .progress-bar-container {
            flex: 1;
            position: relative;
            height: 4px;
            border-radius: 9px;
            background: #5d5d5d;
            cursor: pointer;
            transition:  0.15s ease-in-out;
            &:hover {
              height: 8px;
              .dot {
                width: 20px;
                height: 20px;
              }
            }
            .bar {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: #26dd6c;
              border-radius: 9px;
              z-index: 1;
              width: 0;
              pointer-events: none;
              transition: 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
            }
            .dot {
              position: absolute;
              width: 0px;
              height: 0px;
              background: #26dd6c;
              border-radius: 30px;
              top: 50%;
              transform: translate(-50%, -50%);
              z-index: 2;
              transition: 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
              pointer-events: none;
            }
            .preload-bar {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: #a8a8a8;
              border-radius: 9px;
              width: 0;
              pointer-events: none;
              transition: all 0.3s;
            }
          }
         
        }
        .video-controls-container {
          display: flex;
          align-items: center;
          .progress-time {
            margin-left: 1rem;
            font: 1rem 'Roboto', sans-serif;
            color: rgba(255, 255, 255, 0.5);
            span {
              color:black;
              line-height: 1;
            }
          }
          .volumn-control {
            display: flex;
            align-items: center;
            overflow: hidden;
            &:hover{
              .volumn-bar-container{
                width: 60px;
              opacity: 1;
  
              }
  
            }
            .audio-btn {
              margin-right: 8px !important;
              flex-shrink: 0;
              img {
                width: 20px;
                height: 20px;
              }
            }
            .volumn-bar-container {
              position: relative;
              display: flex;
              width: 0px;
              height: 2px;
              flex-shrink: 0;
              opacity: 0;
            transition: 0.3s ease;
  
              .range-bg {
                position: absolute;
                height: 2px;
                width: 100%;
                background: black;
                pointer-events: none;
                border-radius: 4px;
              }
              .volumn-slider {
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.2);
                outline: none;
                appearance: none;
                -webkit-appearance: none;
                border: none;
                cursor: pointer;
                transition: 0.3s ease-out;
                &::-webkit-slider-thumb {
                  appearance: none;
                  -webkit-appearance: none;
                  width: 15px;
                  height: 15px;
                  background: black;
                  border-radius: 30px;
                  cursor: pointer;
                  border: 0;
                }
              }
            }
          }
          .left-col {
            display: flex;
            align-items: center;
  
            .video-btn {
              margin-right: 1.875rem;
            }
            .play-btn {
              width: 2.5rem;
              height: 2.5rem;
              img {
                height: 1.875rem;
              }
            }
          }
          .right-col {
            display: flex;
            align-items: center;
            margin-left: auto;
            .logo {
              height: 1.1875rem;
            }
          }
        }
      }
    }
  }

  .video-action-container {
    display: flex;
    align-items: center;
  }
  .setting-action-container {
    display: flex;
    width: 27.5rem;
    transition: 0.3s;
    &.hide-action {
      opacity: 0;
      visibility: hidden;
    }
    .media-col {
      width: 50%;
      padding: 0.8125rem 0;
  
      h3 {
        font: 700 1.25rem 'Roboto', sans-serif;
        color: #fff;
        margin: 0;
        padding: 0 1.25rem;
      }
      .setting-input {
        padding: 0.8125rem 0;
        .setting-btn {
          border: 1px solid rgba(255, 255, 255, 0.24);
          border-radius: 16px;
          padding: 0.25rem 0.875rem;
          color: rgba(255, 255, 255, 0.7);
          background: transparent;
          font: 1rem 'Roboto', sans-serif;
  
          img {
            width: 0.75rem;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
  .media-action-container {
    display: flex;
    width: 28.75rem;
    .media-col {
      width: 50%;
    }
  }
  .video-preload-preview {
    position: absolute;
    width: 18.75rem;
    padding: 5px;
    background: #09090d;
    bottom: 1.25rem;
    transform: translateX(-50%);
    text-align: center;
    transition: opacity 0.3s;
    .movie-frame {
      margin-bottom: 5px;
      img {
        width: 100%;
        height: 8.75rem;
        object-fit: cover;
      }
    }
    span {
      font: 1.125rem 'Roboto', sans-serif;
      color: #fff;
    }
  }
  .video-action-item {
    position: relative;
    margin-left: 2.8125rem;
    
    .media-col {
      padding: 0.8125rem 0;
      .menu-header {
        display: flex;
        align-items: center;
        padding: 0.5rem 1.25rem 0.8125rem;
        .back-btn {
          background: transparent;
          padding: 0;
          border: 0;
          margin-right: 1.25rem;
          img {
            width: 6px;
          }
        }
        h3 {
          font: 700 1.25rem 'Roboto', sans-serif;
          color: #fff;
          margin: 0;
        }
      }
    }
    .media-list {
      margin: 0;
      padding: 0;
      list-style: none;
      overflow: hidden;
      li {
        padding: 2px 4px;
      }
      input[type='radio'] {
        display: none;
        &:checked {
          & ~ label {
            color: #fff;
            background-color: rgba(255, 255, 255, 0.05);
  
            &::before {
              opacity: 1;
            }
          }
        }
      }
  
      label {
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.4);
        font: 1rem 'Roboto', sans-serif;
        cursor: pointer;
        padding: 0.875rem 1.25rem;
        transition: 0.3s;
        border-radius: 6px;
        &::before {
          content: '';
          display: inline-block;
          width: 12px;
          height: 8px;
          margin-right: 0.625rem;
         // background: url(../images/checkmark.svg) no-repeat;
          background-size: cover;
          opacity: 0;
          transition: 0.1s ease-in;
        }
        &:hover {
          color: #fff;
          background-color: rgba(255, 255, 255, 0.05);
        }
        .tag {
          display: inline-block;
          padding: 0.25rem 0.625rem;
          border: 1px solid #ffffff;
          border-radius: 13px;
          font: 0.75rem 'Roboto', sans-serif;
          margin-left: 0.8125rem;
          color: #fff;
          opacity: 0.4;
        }
        .tagline {
          color: #23d065;
          font: 700 0.75rem 'Roboto', sans-serif;
          padding: 0 5px;
          align-self: flex-end;
        }
      }
    }
    .video-action-btn {
      position: relative;
      background: transparent;
      border: 0;
      padding: 0;
      img {
        width: 1.875rem;
        height: 1.875rem;
      }
      .tag {
        position: absolute;
        top: -5px;
        right: -10px;
        padding: 2px 2px;
        background: red;
        color: #fff;
        font: 600 9px 'Roboto', sans-serif;
        line-height: 1;
      }
    }
    .video-action-wrapper{
      position: absolute;
      bottom: 100%;
      padding-bottom: 3.4375rem;
      right: 0;
      z-index: 1;
      
    }
    .video-action-block {
      background: rgba(9, 9, 13, 0.9);
      overflow: hidden;
      border-radius: 4px;
    }
    .help-action-block {
      padding: 3.125rem;
      width: 64.375rem;
      border: 1px solid #2f2f2f;
  
      .help-action-block-header {
        margin-bottom: 1.5625rem;
        .close-btn {
          position: absolute;
          top: 1.25rem;
          right: 1.25rem;
          background: transparent;
          border: 0;
          padding: 0;
          img {
            width: 1.5625rem;
          }
        }
        h2 {
          text-align: center;
          margin: 0;
          color: #fff;
        }
      }
      .search-field {
        padding: 1rem 1.25rem;
        background: rgba(255, 255, 255, 0.1);
        margin-bottom: 2.5rem;
        .input-field input {
          font: 1rem 'Roboto', sans-serif;
        }
        .search-action button.close-btn {
          width: 1.375rem;
        }
      }
      .issue-item-container {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2.1875rem;
        .issue-item {
          width: 33.3%;
          padding: 0.5rem 0;
          .form-check-input {
            background-color: transparent;
            border-color: #fff;
            border-radius: 0;
  
            &:checked {
              background-color: black;
              border-color: black;
            }
          }
          .form-check {
            margin: 0;
            color: #fff;
            font: 500 1.25rem 'Roboto', sans-serif;
          }
        }
      }
      .issue-input {
        margin-bottom: 2.8125rem;
        textarea {
          width: 100%;
          background: rgba(255, 255, 255, 0.1);
          border: 0;
          padding: 0.9375rem 1.5625rem;
          resize: none;
          font: 1rem 'Roboto', sans-serif;
          color: #fff;
          &::placeholder {
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }
      .issue-form-action {
        text-align: center;
        .btn-outline-primary {
          font: 500 1.25rem 'Roboto', sans-serif;
          padding: 1rem 5rem;
          border-color: black;
          border-radius: 4px;
          color:black;
          &:hover {
            background: black;
            color: #fff;
          }
        }
      }
    }
    .setting-menu {
      width: 16.25rem;
    }
  }
  .control-open video::-webkit-media-text-track-container{
    transform: translateY(-120px) !important;
  
  }
  video::cue {
    font: 400 1.875rem 'Roboto', sans-serif;
    background: transparent;
  }
  video::-webkit-media-text-track-container {
    transform: translateY(-50px) !important;
    transition: 0.65s ease;
  }
  video::-webkit-media-text-track-display-backdrop {
    background: transparent;
  }
  .fadeInDown-appear {
    opacity: 0;
    transform: translateY(-25px);
  }
  .fadeInDown-enter {
    opacity: 0;
    transform: translateY(-25px);
  
  }
  .fadeInDown-enter-done {
    opacity: 1;
    transform: translateY(0px);
    transition:  300ms ease-in-out;
  }
  .fadeInDown-exit {
    opacity: 1;
    transform: translateY(0px);
  
  }
  .fadeInDown-exit-active {
    opacity: 0;
    transform: translateY(-25px);
    transition:  300ms ease-in-out;
  }
  .fadeInUp-appear {
    opacity: 0;
    transform: translateY(25px);
  }
  .fadeInUp-enter {
    opacity: 0;
    transform: translateY(25px);
  
  }
  .fadeInUp-enter-done {
    opacity: 1;
    transform: translateY(0px);
    transition:  300ms ease-in-out;
  }
  .fadeInUp-exit {
    opacity: 1;
    transform: translateY(0px);
  
  }
  .fadeInUp-exit-active {
    opacity: 0;
    transform: translateY(25px);
    transition:  300ms ease-in-out;
  }
//   @keyframes rotate {
//     to {
//       rotate: 360deg;
//     }
//   }
/* Main Container */

.app {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* Video */

.video {
  width: 100vw;
  height: 100vh;
}

/* Controls */

.controlsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  background-color: transparent;
  margin-top: -50vw;
  padding: 0 40px;
  z-index: 20;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 18rem;
  margin: auto;
}

.controlsIcon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-left: 10rem;
  margin-right: 10rem;
}

.controlsIcon--small {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 10rem;
  margin-right: 10rem;
}

/* The time controls section */

.timecontrols {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: 4rem;
  margin-left: 10vw;
}

.time_progressbarContainer {
  background-color: gray;
  border-radius: 15px;
  width: 75vw;
  height: 5px;
  z-index: 30;
  position: relative;
  margin: 0 20px;
}

.time_progressBar {
  border-radius: 15px;
  background-color: indigo;
  height: 100%;
}

.controlsTime {
  color: white;
}

.series-detail-page{
  padding-bottom: 0.9375rem;
.ant-tabs-top > .ant-tabs-nav::before{
  border-bottom: 1px solid #D4D4D4;
}
      .ant-tabs-tab{
          padding: 12px 20px;
          font-size: 14px;
          font-weight: 500;
          color: #000000;
          .ant-tabs-ink-bar{
              color: #098EDF;
          }
          &.ant-tabs-tab-active{
              .ant-tabs-tab-btn{
  
                  color:   #098EDF;
              }
          }
      }
  .series-detail-card{
      padding:2.8125rem 1.5625rem;
      background: #fff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      h3{
          font-weight: 600;
          font-size: 1.125rem;
          color: #000000;
          margin-bottom: 1.875rem;
          text-align: center;
      }
      .overview-card{
          display: flex;
          align-items: center;
          padding: 1.6875rem 1.875rem;
          border: 1px solid #B9B9B9;
          border-radius: 4px;
          .overview-card-content{
              flex: 1;
              p{
                  color: #666666;
                  font-size: 0.875rem;
                  font-weight: 500;
                  margin-bottom: 4px;
              }
              .count{
                  display: flex;
                  align-items: center;
                  span{
                      font-size: 1.5rem;
                      color: #000000;
                      font-weight: 600;
                  }
                  svg{
                      margin-left: 0.5rem;
                  }
              }
          }
      }
  }
  .video-container{
      margin: 3.75rem 0 1.5625rem;
      video{
          width: 100%;
      }
  }
  .video-detail-block{
      h4{
          font-size: 1.125rem;
          font-weight: 600;
          color: #000000;
          margin-bottom: 0.9375rem;
      }
      .description-block{
          label{

              display: block;
              font-weight: 500;
              font-size: 15px;
              color: #000000;
              margin-bottom: 5px;
          }
          p{
              font-size: 14px;
              font-weight: 500;
              color: #333333;
          }
          a{
              font-size: 14px;
              color: #2D9CDB;
          }
      }
  }
  .series-detail-panel{
      background: #FFFFFF;
border: 2px solid #2196F3;
border-radius: 6px;
margin-bottom: 15px;
.video-panel-header{
  padding: 1.125rem 1.6875rem;
  border-bottom: 1px solid #E6E9EB;
  h3{
      font-size: 1.0625rem;
      color: #000000;
      font-weight: 600;
  }
}
.video-panel-body{
  padding: 1.25rem 1.5625rem;
  .company{
      margin-bottom: 25px;
      img{
          height: 35px;
          margin-bottom: 6px;
      }
      p{
          display: flex;
font-size: 13px;
font-weight: 600;
color: #000000;
span{
  font-weight: 400;
  color: #2196F3;
}
      }
  }
  .video-detail-col{
      padding: 10px 0;
      label{
          color: #000000;
          font-weight: 500;
          font-size: 14px;
      }
      p{
          color: #333333;
          font-size: 15px;
      }
  }
}
  }
  .video-features-panel{
      padding: 10px 30px;
      background: #FFFFFF;
border: 1px solid #E6E9EB;
border-radius: 6px;
li{
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #DCDCDC;
  &:last-child{
      border-bottom: 0;
  }
  label{
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      flex: 1;
      padding-right: 10px;
  }
  p{
      font-size: 14px;
      color: #333333;
  }
}
  }
}