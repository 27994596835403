.add-subscription-container {
  .form-container {
    width: 75%;
    margin: 0 auto;
    .form-field-wrapper {
      padding-bottom: 1.875rem;
    }
    .ant-divider-with-text-left {
      font-size: 0.9375rem;
      font-weight: 600;
      color: #000;
      border-top-color: #d4dee2;
      margin-bottom: 1.875rem;
      .ant-divider-inner-text {
        padding-left: 0;
      }
      &::before {
        display: none;
      }
    }
  }
}
